<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- 评论列表 -->
    <!-- <h2 class="sec-title">评论审核</h2> -->

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-row>
        <el-select
          v-model="status"
          @change="changeStatus"
          placeholder="请选择状态"
          size="small"
        >
          <el-option
            v-for="item in b_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-row>
      <el-tab-pane label="圈子评论审核" name="comment">
        <el-table
          :data="tabledata"
          border
          style="width: 100%"
          v-loading="isLoading"
        >
          <el-table-column align="center" prop="id" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="content" label="发布内容">
          </el-table-column>
          <el-table-column align="center" prop="create_time" label="发布时间">
          </el-table-column>
          <el-table-column align="center" prop="user_id" label="发布用户">
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 3" style="color: #66b1ff"
                >待审核</span
              >
              <span v-else-if="scope.row.status == 1" style="color: #85ce61"
                >通过</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #f56c6c"
                >拒绝</span
              >
              <span v-else-if="scope.row.status == 4" style="color: #a6a9ad"
                >违规</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="300px">
            <template slot-scope="scope">
              <div>
                <el-button
                  v-if="scope.row.status == 3 || scope.row.status == 1"
                  type="danger"
                  size="small"
                  @click="updata(scope.row.id, 2)"
                  >拒绝</el-button
                >
                <el-button
                  v-if="scope.row.status == 3"
                  type="primary"
                  size="small"
                  @click="updata(scope.row.id, 1)"
                  >通过</el-button
                >
                <!-- <el-button 
                type="primary" 
                size="small"
                @click="updata(scope.row.id, 4)"
                  >永久禁言</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="totalNumber"
        ></paging-fy>
      </el-tab-pane>

      <el-tab-pane label="圈子审核" name="baby">
        <el-table
          :data="b_tableData"
          border
          style="width: 100%"
          v-loading="isLoading"
        >
          <el-table-column align="center" prop="id" label="序号">
          </el-table-column>
          <el-table-column
            align="center"
            width="320"
            prop="content"
            label="发布内容"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="map.userinfo.nick_name"
            label="发布者"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="map.masterSetPriceEntity.title"
            label="推荐的课程"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="map.mechanism_name"
            label="所属机构"
          >
          </el-table-column>
          <el-table-column align="center" prop="share_count" label="分享数">
          </el-table-column>
          <el-table-column align="center" prop="comment_count" label="评论数">
            <template slot-scope="scope">
              <span @click="b_comments(scope.row.id)">{{
                scope.row.comment_count
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="collect_count" label="点赞数">
          </el-table-column>
          <!-- :filters="[
            { text: '完全公开', value: '1' },
            { text: '仅好友可见', value: '2' },
            { text: '审核中', value: '3' },
            { text: '违规', value: '4' },
          ]"
          :filter-method="filterTag"
          filter-placement="bottom-end" -->
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: #85ce61"
                >完全公开</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #85ce61"
                >仅好友可见</span
              >
              <span v-else-if="scope.row.status == 3" style="color: #66b1ff"
                >审核中</span
              >
              <span v-else-if="scope.row.status == 4" style="color: #a6a9ad"
                >违规</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="300px">
            <template slot-scope="scope">
              <div>
                <el-button
                  type="danger"
                  @click="b_updata(scope.row.id, 4)"
                  size="small"
                  >拒绝</el-button
                >
                <el-button
                  type="primary"
                  @click="b_updata(scope.row.id, 1)"
                  size="small"
                  >通过</el-button
                >
                <el-button
                  type="primary"
                  @click="b_updata(scope.row.id, 5)"
                  size="small"
                  >永久禁言</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="b_handleCurrentChange"
          :currentPage="b_currentPage"
          :total="b_totalNumber"
        ></paging-fy>
      </el-tab-pane>

      <el-tab-pane label="商品评价审核" name="commentc">
        <el-table
          :data="tabledata"
          border
          style="width: 100%"
          v-loading="isLoading"
        >
          <el-table-column align="center" prop="id" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="content" label="发布内容">
          </el-table-column>
          <el-table-column align="center" prop="create_time" label="发布时间">
          </el-table-column>
          <el-table-column align="center" prop="user_id" label="发布用户">
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 3" style="color: #66b1ff"
                >待审核</span
              >
              <span v-else-if="scope.row.status == 1" style="color: #85ce61"
                >通过</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #f56c6c"
                >拒绝</span
              >
              <span v-else-if="scope.row.status == 4" style="color: #a6a9ad"
                >违规</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="300px">
            <template slot-scope="scope">
              <div>
                <el-button
                  v-if="scope.row.status == 3"
                  type="danger"
                  size="small"
                  @click="updata(scope.row.id, 2)"
                  >拒绝</el-button
                >
                <el-button
                  v-if="scope.row.status == 3"
                  type="primary"
                  size="small"
                  @click="updata(scope.row.id, 1)"
                  >通过</el-button
                >
                <el-button
                  type="primary"
                  @click="updata(scope.row.id, 4)"
                  size="small"
                  >永久禁言</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="totalNumber"
        ></paging-fy>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加圈子 -->
    <el-dialog title="添加评论" :visible.sync="circleDialogVisible" width="50%">
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="评论内容">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="commit_content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="评论图片">
            <images-uploader
              @getImgUrl="upload_imgs"
              :urls="commit_pic"
            ></images-uploader>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="comdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCommit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "InformationReview",
  //信息审核
  data() {
    return {
      nav: {
        firstNav: "内容管理",
        secondNav: "信息审核",
      },
      isLoading: true,
      content: "",
      masterSetPriceEntity: [],
      value: "",
      currentPage: 1,
      totalNumber: 0,
      tabledata: [],
      userID: "",
      activeName: "comment",
      //baby show
      b_currentPage: 1,
      circleDialogVisible: false,
      commit_content: "",
      curCourse: {},
      commit_pic: [],
      b_totalNumber: 0,
      b_tableData: [],
      b_value: 1,
      b_show: 1,
      status: 3,
      b_options: [
        {
          value: 1,
          label: "完全公开",
        },
        {
          value: 2,
          label: "仅好友可见",
        },
        {
          value: 3,
          label: "审核中",
        },
        {
          value: 4,
          label: "违规",
        },
      ],
    };
  },
  created() {
    // 路由传参
    // console.log(this.$route.query.userID)
    this.userID = this.$route.query.userID;
  },
  mounted() {
    if (this.activeName == "comment") {
      this.Getlist();
    } else if (this.activeName == "commentc") {
      this.Getlistcc();
    } else {
      this.GetEvaluation();
    }
  },
  methods: {
    changeStatus() {
      if (this.activeName == "comment") {
        this.Getlist();
      } else if (this.activeName == "commentc") {
        this.Getlistcc();
      } else {
        this.GetEvaluation();
      }
    },
    //添加圈子
    submitCommit() {
      let data = {
        content: this.commit_content,
        master_set_price_id: this.curCourse.id,
        picts: this.commit_pic,
        mechanism_id: this.curCourse.mechanism_id,
        // categories:this.curCourse.categories,
        // categories_child:this.curCourse.categories_child,
      };
      this.$axios({
        url: "message/noteUser/insertRobot",
        method: "post",
        data,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.getCourseList();
          this.comdialogVisible = false;
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    handleClick(tab) {
      this.isLoading = true;
      if (tab.name == "comment") {
        this.Getlist();
      } else if (tab.name == "commentc") {
        this.Getlistcc();
      } else {
        this.GetEvaluation();
      }
    },
    Getlist() {
      let url = "/message/noteComment/queryManagerListPageSystem";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            is_teach_paypal: true,
            note_id: this.userID,
            status: this.status || null,
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.tabledata = res.data.data.rows;
          this.totalNumber = res.data.data.total;
        });
    },
    Getlistcc() {
      let url = "/user/masterComment/queryListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            is_teach_paypal: true,
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.tabledata = res.data.data;
          this.totalNumber = res.data.data.total;
        });
    },
    updata(id, status) {
      let url = "/message/noteComment/update";
      if (this.activeName == "commentc") {
        url = "/user/masterComment/update";
      }
      let data = {
        id,
        status,
      };
      this.$axios.post(url, data).then((res) => {
        this.$message({
          message: "成功",
          type: "success",
        });
        if (this.activeName == "comment") {
          this.Getlist();
        } else if (this.activeName == "commentc") {
          this.Getlistcc();
        } else {
          this.GetEvaluation();
        }
      });
    },
    handleCurrentChange(val) {
      this.isLoading = true;
      this.currentPage = val;
      this.Getlist();
    },
    //baby
    GetEvaluation() {
      let url = "/message/noteUser/queryManagerListPage";
      let par = {
        is_teach_paypal: true,
        pageSize: 10,
        currentPage: this.b_currentPage,
        status: this.b_value,
      };
      this.$axios
        .get(url, {
          params: par,
        })
        .then((res) => {
          this.isLoading = false;
          this.b_tableData = res.data.data.rows;
          this.b_totalNumber = res.data.data.total;
        });
    },
    b_updata(id, status) {
      let url = "/message/noteUser/update";
      let data = {
        id,
        status,
      };
      this.$axios.post(url, data).then((res) => {
        this.GetEvaluation();
        this.$message({
          message: "成功",
          type: "success",
        });
      });
    },
    b_comments(e) {
      console.log("xxx", e);
      this.$router.push({
        name: "InformationReview",
        query: {
          userID: e,
        },
      });
    },
    b_handleCurrentChange(val) {
      this.isLoading = true;
      this.b_currentPage = val;
      this.GetEvaluation();
    },
    b_remoteMethod() {
      this.isLoading = true;
      this.GetEvaluation();
    },
    b_filterTag(value, row) {
      if (this.b_show == 10) {
        console.log(row.status, value);
        this.b_show = 1;
      }
      this.b_show += 1;
      return row.status == value;
    },
  },
};
</script>

<style></style>
